export const doctorEnrollmentTicketBody = (request) => {
  let {
    firstName,
    middleName,
    lastName,
    prcLicenseNumber,
    mobileNumber,
    clinicLandline,
    alternativeLandline,
    email,
    specialization,
    otherSpecialization,
    hospitalClinic,
    otherHospital,
    clinicHours,
    clinicAddress,
    secondaryHospitalClinic,
    secondaryOtherHospital,
    secondaryClinicHours,
    secondaryClinicAddress,
    fullName, //referral
    hasReceivedPatientBriefing,
    whenWillPatientEnroll,
  } = request

  return `Personal Details
  First Name: ${firstName}
  Middle Name: ${middleName}
  Last Name: ${lastName}
  PRC License Number: ${prcLicenseNumber}
  Mobile Number: ${mobileNumber}
  Clinic Landline Number: ${clinicLandline || "N/A"}
  Alternative Clinic Landline: ${alternativeLandline || "N/A"}
  Email: ${email}
  Specialization: ${
    specialization === "Other" ? otherSpecialization : specialization
  }
  ---
  Primary Office:
  Hospital/Clinic: ${
    hospitalClinic?.value !== "Other" ? hospitalClinic?.value : otherHospital
  }
  Clinic Hours: ${clinicHours}
  Street Address: ${clinicAddress?.streetAddress}
  City: ${clinicAddress?.city?.value}
  Province: ${clinicAddress?.province?.value}
  ---
  Secondary Office:
  Hospital/Clinic: ${
    secondaryHospitalClinic?.value !== "Other"
      ? secondaryHospitalClinic?.value
        ? secondaryHospitalClinic?.value
        : "N/A"
      : secondaryOtherHospital || "N/A"
  }
  Clinic Hours: ${secondaryClinicHours || "N/A"}
  Street Address: ${secondaryClinicAddress?.streetAddress || "N/A"}
  City: ${
    (secondaryHospitalClinic?.value && secondaryClinicAddress?.city?.value) ||
    "N/A"
  }
  Province: ${
    (secondaryHospitalClinic?.value &&
      secondaryClinicAddress?.province?.value) ||
    "N/A"
  }
  ---
  Referral: ${fullName || "N/A"}
  Has received patient briefing?: ${hasReceivedPatientBriefing}
  When will patient enroll?: ${whenWillPatientEnroll}
  `
}
